import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import getRatio from "../utils/getRatio"
import convertHex from "../utils/convertHex"
import IFrame from "../components/iframe"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  location,
}) {
  const { file, markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark // "html" variable removed
  let players = []
  let ids = []
  useEffect(() => {
    // On mount, check to see if the API script is already loaded
    if (!window.YT) {
      // If not, load the script asynchronously
      const tag = document.createElement("script")
      tag.src = "https://www.youtube.com/iframe_api"

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = loadVideo

      const firstScriptTag = document.getElementsByTagName("script")[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    } else {
      // If script is already there, load the video directly
      loadVideo()
    }
  })
  const loadVideo = () => {
    // the Player object is created uniquely based on the id in props
    frontmatter.demos.map((id, index) => ids.push("youtube-video-" + index))
    ids.map((id, index) =>
      players.push(
        new window.YT.Player(id)
      )
    )
  }
  const startFrame = id => {
    players[id].playVideo()
  }
  const stopFrame = id => {
    players[id].stopVideo()
  }
  return (
    <Layout front={frontmatter.colour}>
      <SEO
        image={file.publicURL}
        title={frontmatter.title}
        location={location}
      />
      <div className="solution-page">
        <div
          className="tile"
          style={{
            backgroundImage: "url(" + frontmatter.tileImg.publicURL + ")",
            backgroundRepeat: "repeat",
            backgroundPositionY: "56px",
            backgroundAttachment: "fixed",
            // animation: "marquee 60s infinite linear"
          }}
        >
          <div
            style={{
              backgroundImage: "url(" + frontmatter.tileIcon.publicURL + ")",
              backgroundRepeat: "repeat",
              backgroundPositionY: "56px",
              backgroundAttachment: "fixed",
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          ></div>
          <div className="container -sol " style={{ background: "inherit" }}>
            <div className="title-section">
              <h3 className="title -sub">
                <span className="title-blur" aria-hidden="true" style={{ visibility: "hidden" }}>
                  {frontmatter.title}
                </span>
                <span
                  className="title-blur"
                  css={{
                    position: "absolute !important",
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: "0px",
                    width: "100%",
                    maxWidth: "570px",
                    zIndex: "1 !important",
                    "@media(min-width: 768px)": {
                      left: "20px",
                      transform: "translateX(0px)",
                      top: "unset",
                      bottom: "0px"
                    },
                  }}
                >
                  {frontmatter.title}
                </span>
                {/* <Link className="title-blur" to={frontmatter.path}>{frontmatter.title}</Link> */}
              </h3>
              <div className="title-icon">
                <img src={frontmatter.icon.publicURL} alt="Icon" />
              </div>
            </div>
          </div>
          <div
            className="fade"
            style={{
              background: `linear-gradient(90deg, rgba(0,0,0,0.2) 0%, ${convertHex(
                frontmatter.colour,
                50
              )} 100%)`,
            }}
          ></div>
        </div>
        <div className="content container -sol">
          <div
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
          <div className="table">
            <div className="thead">
              <div className="tr">
                <div className="th">{frontmatter.table.title}</div>
              </div>
            </div>
            <div className="tbody">
              {frontmatter.table.rows.map(row => (
                <div className="tr">
                  {row.text ? (
                    <>
                      <div className="td">{row.title}</div>
                      <div className="td info">{row.text}</div>
                    </>
                  ) : (
                    <>
                      <div className="td">{row.title}</div>
                    </>
                  )}
                </div>
              ))}
              <div className="tr">
                <div className="td"></div>
              </div>{" "}
              {/*Added to make space at bottom of table */}
            </div>
          </div>

          <div
            css={{
              "@media(min-width: 768px)": {
                maxWidth: frontmatter.imgWidth + "px !important",
              },
            }}
            className={`image ${frontmatter.tallVideo ? 'tall-video':''}`}
          >
            <Img
              className="p-image"
              fluid={frontmatter.frontImg.childImageSharp.fluid}
            />
            <div className="p-video">
              <IFrame
                ratio={getRatio(markdownRemark)}
                videoSrcURL={`https://player.vimeo.com/video/${frontmatter.mainVid}?background=1`}
                videoTitle="High Precision Visual Tracking for Resource Constrained Devices"
              />
            </div>
          </div>
          <div className="demos-links">
            <div className="demos-parent">
              <h4>Additional Videos</h4>
              <div className="demos">
                {frontmatter.demos.map((demo, index) => (
                  <div
                    key={"vid_demo_link" + markdownRemark.id + index}
                    className="demo"
                  >
                    <IFrame
                      stop={stopFrame}
                      start={startFrame}
                      idName={index}
                      popout={true}
                      ratio={(315 / 560) * 100 + "%"}
                      videoSrcURL={`${demo.url}`}
                      videoTitle={demo.title}
                    />
                    <p>{demo.title}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="link">
              <h4>Additional Links</h4>
              {frontmatter.links.map((demo, index) => (
                <div key={"demo_link" + markdownRemark.id + index}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: frontmatter.colour,
                    }}
                    href={demo.url}
                  >
                    {demo.text}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        path
        colour
        icon {
          publicURL
        }
        tileImg {
          publicURL
        }
        tileIcon{
          publicURL
        }
        frontImg {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainVid
        vidWidth
        vidHeight
        imgWidth
        tallVideo
        demos{
          title
          url
          ratio
        }
        links{
          text
          url
        }
        table{
          title
          rows{
            title
            text
          }
        }
        summary
      }
    }
    file(relativePath: { eq: "logo.png" }) {
      publicURL
    }
  }
`
